:root {
  --main-bg-color: #E5E5E5;
  --main-theme-color: #5C3DA4;
  --main-light-text: #7d92a1;
  --main-dark-text: #324552;
}
.error {
  color: rgb(241, 119, 119) !important;
}
body {
  background-color: var(--main-bg-color) !important;
  overflow-x: hidden;
  font-family: Roboto;
  font-style: normal;
}
.relative {
  position: relative;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}